@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

body{
    overflow-x: hidden !important;
    font-family: 'Montserrat', sans-serif;
    background-color: rgb(27, 27, 27);
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}


.react-tel-input{
  width: 38px !important;
}
.react-tel-input input{
  background:transparent !important;
  /* width: 720px !important; */
  color:white !important;
  padding:8px 41px !important ;
   border: 0px !important;
}
.react-tel-input input::placeholder{
  color: #fff !important;
}
.react-tel-input input:focus{
  box-shadow: none !important;
}
.country-list li span{
  color: black !important;
}
.react-tel-input .country-list {
   width: 400px !important;

}
.country-list li input{
  border: 1px solid black !important;
  color: black !important;
}
.paginationBttns {
    width: 100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 10px 15px;
   
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #DA3D4D;
    color: #DA3D4D;
    cursor: pointer;
    background-color: white;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: #DA3D4D;
  }
  
  .paginationActive a {
    color: white;
    background-color: #DA3D4D;
  }
  
  .paginationDisabled a {
    color: white;
    background-color: #DA3D4D;
  }
  .earning-sec {
    border-top: 2px solid #282945;
    border-bottom: 2px solid #282945;
    padding: 50px 0;
    .sec-tag {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      margin-bottom: 30px;
      @include media("<=phoneH") {
        font-size: 18px;
      }
    }
    .table-block {
      background: #282945;
      border-radius: 10px;
      @include media("<=tablet") {
        overflow: auto;
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
      }
      .table-row {
        padding: 6px 40px;
        @include media("<=tablet") {
          width: 830px;
        }
        &:first-child {
          margin-bottom: 20px;
          padding: 20px 40px;
          border-bottom: 2px solid #282945;
        }
        &:last-child {
          margin-bottom: 20px;
        }
        .row-item {
          flex: 1;
          align-items: center;
          justify-content: center;
          &:first-child {
            flex: 0.3;
            @include media("<=phoneH") {
              flex: 0.1;
            }
          }
          .row-lbl {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
          }
        }
      }
    }
  }
