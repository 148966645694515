@tailwind base;
@tailwind components;
@tailwind utilities;

.primary-btn {
  @apply bg-[#e15764] px-2 md:px-8 text-[16px]  rounded-full text-white   font-semibold;
}
.success-btn {
  @apply bg-[#e15764] px-4 md:px-8 text-[18px]   rounded-full text-white    font-medium;
}
.secondary-btn {
  @apply bg-transparent text-[18px] border-[#e15764] border-2 py-2 px-3 md:px-8  rounded-full text-white   font-semibold;
}
.list {
  @apply px-10;
}



.accordion {
  @apply divide-y divide-gray-200;
}

.accordion-item {
  @apply relative;
}

.accordion-title {
  @apply flex items-center justify-between p-4 cursor-pointer;
}

.accordion-button {
  @apply text-left text-xl font-medium;
}

.accordion-content {
  @apply max-h-0 overflow-hidden transition-all ease-in-out duration-300;
}

.accordion-content p {
  @apply p-4;
}

.accordion-content.active {
  @apply max-h-[300px]; /* Set a height that accommodates your content */
}

@keyframes scrollUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
}

.scrolling-container {
  overflow: hidden;
  height: 140px;
}

.scrolling {
  
  animation: scrollUp 0.5s reverse infinite;
}



/* Scrollbar Styling */
.overflow-y-auto::-webkit-scrollbar {
  width: 5px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #685442; 
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
    size: 10px !important;
  /* width: 120px; */
  background-color:black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 13%;
  /* left: 50%; */
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}